import { ReactElement, useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { callApi } from '../functions/callApi';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useLocation, useNavigate } from 'react-router-dom';

export interface PropsShape {
  name: string;
  subName?: string;
  children?: ReactElement;
}

const UserMenu = ({ name, subName, children }: PropsShape): ReactElement => {
  const menuTransition = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { userState, setUserState } = useGlobalUserState();
  const location = useLocation();
  const navigate = useNavigate();

  const getOrganizations = async (): Promise<void> => {
    try {
      const res = await callApi<any>(
        `organizations?userId=${userState.data?.accountKey}`,
        'GET'
      );
      if (res.status === 404) {
        setUserState((prevState: any) => ({
          data: {
            ...prevState.data,
            isActive: true,
            organizations: [],
          },
          isSwitchingAccount: prevState.isSwitchingAccount,
        }));
        if (location.pathname === '/account/people') navigate('/files');
      } else if (res.status !== 200) throw new Error(res.message);
      else {
        const isCurrentOrg = res.find(
          (item: any) =>
            item.organization_data.account_key ===
            userState.currentOrganization?.accountKey
        );
        if (!isCurrentOrg) {
          setUserState((prevState: any) => ({
            data: {
              ...prevState.data,
              organizations: res,
              isActive: true,
            },
            isSwitchingAccount: prevState.isSwitchingAccount,
          }));
          if (location.pathname === '/account/people') navigate('/files');
        } else if (
          JSON.stringify(res) !== JSON.stringify(userState.data?.organizations)
        ) {
          setUserState((prevState: any) => ({
            ...prevState,
            data: {
              ...prevState.data,
              organizations: res,
            },
          }));
        }
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e?.message || e);
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      getOrganizations();
    })();
  }, []);

  return (
    <div className='UserMenu'>
      {isOpen && (
        <div
          className='UserMenu--overlay'
          onClick={(): void => setIsOpen(!isOpen)}
        ></div>
      )}
      <div
        className='UserMenu--button'
        onClick={async (): Promise<void> => {
          await getOrganizations();
          setIsOpen(!isOpen);
        }}
      >
        <div className='UserMenu--avatar' />
        <div className='UserMenu--info'>
          <div className='UserMenu--name'>{name}</div>
          {subName && subName.length > 0 && (
            <div className='UserMenu--subname'>{subName}</div>
          )}
        </div>
      </div>
      <CSSTransition
        nodeRef={menuTransition}
        in={isOpen}
        timeout={300}
        classNames={`UserMenu--transition`}
        unmountOnExit
      >
        <div className='UserMenu--menu' ref={menuTransition}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};
export { UserMenu };

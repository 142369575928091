import { ReactElement, useState, useRef, ChangeEvent, useEffect } from 'react';

export interface PropsShape {
  children: ReactElement;
  encrypt?: boolean;
  setFiles?: any;
  multiple?: boolean;
  directory?: boolean;
}

declare module 'react' {
  // eslint-disable-next-line no-undef
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    directory?: string;
    webkitdirectory?: string;
  }
}

const FileInput = ({
  children,
  setFiles,
  encrypt = false,
  multiple = false,
  directory = false,
}: PropsShape): ReactElement => {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [cssClass, setCssClass] = useState<string>('');

  const processFiles = (e: ChangeEvent<any>): void => {
    const arr: File[] = Array.from(e.target.files);
    setFiles(arr, encrypt);
    setIsDragOver(false);
  };

  const dragOver = (): void => {
    !isDragOver && setIsDragOver(true);
  };
  const dragLeave = (): void => {
    isDragOver && setIsDragOver(false);
  };

  useEffect(() => {
    if (isDragOver) {
      setCssClass('FileInput--input-show-drag');
    } else {
      setCssClass('FileInput--input-show');
    }
  }, [isDragOver]);

  return (
    <div className='FileInput' onDragOver={dragOver}>
      {multiple && (
        <input
          type='file'
          className={cssClass}
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          value=''
          ref={fileInputField}
          onChange={processFiles}
          multiple={multiple}
        />
      )}
      {directory && (
        <input
          type='file'
          directory=''
          webkitdirectory=''
          className={cssClass}
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          value=''
          ref={fileInputField}
          onChange={processFiles}
        />
      )}

      {children}
    </div>
  );
};
export { FileInput };

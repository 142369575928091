export interface usersShape {
  account_key: string;
  account_role: {
    id: string;
    type: 'ADMIN' | 'SUPERADMIN' | 'USER' | 'CUSTOMER';
  };
  bill_day: number;
  billing_settings: {
    account_id: string;
    discount_percent: number;
    id: string;
    reseller_email?: string;
    reseller_percent: number;
  };
  created: number;
  has_opinvites: boolean;
  has_nodes: boolean;
  is_archived: boolean;
  is_enabled: boolean;
  owner_email: string;
  owner_name: string;
  telos_account: string;
  is_active: boolean;
}

export interface apiKeysShape {
  api_key: string;
  created: number;
  expiration: number;
  comment: string;
}

export interface fileUsageShape {
  download_size_bytes: number;
  download_price_cents: number;
  downloads_count: number;
  file_hash: string;
  file_key: string;
  file_name: string;
  file_size: number;
  storage_price_cents: number;
  stored_milliseconds: number;
  storage_size_bytes: number;
  total_price_cents: number;
  upload_size_bytes: number;
  upload_price_cents: number;
  upload_date: string;
}

export interface filesUsageShape {
  period_start: number;
  period_end: number;
  files: fileUsageShape[];
}

export interface fundingShape {
  account_id: string;
  card_brand:
    | 'American Express'
    | 'Diners Club'
    | 'Discover'
    | 'JCB'
    | 'MasterCard'
    | 'UnionPay'
    | 'Visa'
    | 'Unknown';
  card_exp_month: number;
  card_exp_year: number;
  card_last_four: string;
  id: string;
  payment_method: string;
  stripe_source_id: string;
}

export interface chargesShape {
  account_id: string;
  account_payment_details_id: string;
  accountpaymentdetails: {
    id: string;
    account_id: string;
    payment_method: string;
    is_default: boolean;
    stripe_source_id: string;
  };
  amount: number;
  created_at: number;
  currency: {
    code: string;
    minor_digits: number;
    symbol: string;
  };
  currency_code: string;
  failure_message?: string;
  id: string;
  status: string;
  stripe_charge_id: string;
  stripe_invoice_id: string;
}
export interface dbInvoicesShape {
  bill_created_at?: number;
  created_at: number;
  download_price_us_cents: number;
  download_total_gb: number;
  finalized_at?: number;
  id: string;
  is_not_paid_11_days_processed: boolean;
  is_not_paid_31_days_processed: boolean;
  is_paid: boolean;
  marked_uncollectible_at?: string;
  negative_account_balance: number;
  owner: string;
  paid_at?: number;
  period_end: number;
  period_name: string;
  period_start: number;
  refunded_by_account_balance?: number;
  status: string;
  storage_price_us_cents: number;
  storage_total_gb: number;
  stripe_invoice_id?: string;
  stripe_invoice_item_id?: string;
  stripe_invoice_link?: string;
  stripe_invoice_period_name?: string;
  total_us_cents: number;
  total_us_cents_to_pay: number;
  upload_price_us_cents: number;
  upload_total_gb: number;
  voided_at?: number;
}
export interface invoiceShape {
  charges: chargesShape[];
  db_invoices: dbInvoicesShape[];
  download_price_us_cents: number;
  download_total_gb: number;
  invoice_stripe_id: string;
  period_name: string;
  storage_price_us_cents: number;
  storage_total_gb: number;
  total_us_cents: number;
  total_us_cents_to_pay: number;
  upload_price_us_cents: number;
  upload_total_gb: number;
  period_start: number;
  period_end: number;
}

export interface nodesShape {
  id: string;
  account_key: string;
  public_v4: string | null;
  peer_id: string | null;
  node_name: string;
  city: string | null;
  country: string;
  continent: string;
  latitude: string;
  longitude: string;
  cpu_load: number;
  ram_load: number;
  bandwidth: number;
  storage_avail: number;
  metadata: {
    isTosAccepted: boolean;
    isTosModalBottomReached: boolean;
  };
  is_enabled: boolean;
  is_deleted: boolean;
  is_online: boolean;
  created: number;
  last_seen: number;
  is_healthy: boolean;
  upload_connection_speed: number;
  download_connection_speed: number;
}

export interface billingDataShape {
  period_name: string;
  period_end: number;
  period_start: number;
  overdue_invoice: any;
  total_price_us_cents: number;
  current_discount_percent: number;
  download_price_us_cents: number;
  downloaded_month_gb: number;
  storage_price_us_cents: number;
  storage_month_gb: number;
  upload_price_us_cents: number;
  uploaded_month_gb: number;
  current_billing_period?: boolean;
}

interface accountShape {
  maxThreshold: any;
  isPreviewOn: boolean;
  isNodeOperator: boolean;
  invoices: invoiceShape[];
  canUpload: boolean;
  isFolder: boolean;
  name: string;
  email: string;
  lastUpload: number;
  sortBy: 'created' | '-created' | 'name' | '-name';
  apiKeys: apiKeysShape[];
  encryption?: encryptionDataShape;
  accountKey: string;
  has_credit_card: boolean;
  fileUsage?: filesUsageShape[];
  funding?: fundingShape[];
  storageNodes?: nodesShape[];
  userRole: 'SUPERADMIN' | 'ADMIN' | 'NODE_HANDLER' | 'CUSTOMER';
  pulse?: {
    accountDeletedMail: boolean;
    accountDeletedPulse: boolean;
    accountFrozenMail: boolean;
    accountFrozenPulse: boolean;
    failedPaymentMail: boolean;
    failedPaymentPulse: boolean;
    invoiceGeneratedMail: boolean;
    invoiceGeneratedPulse: boolean;
    noPaymentMethodMail: boolean;
    noPaymentMethodPulse: boolean;
    successfulPaymentMail: boolean;
    successfulPaymentPulse: boolean;
    thresholdNotReachedMail: boolean;
    thresholdNotReachedPulse: boolean;
  };
  usage?: {
    current_rates: {
      download: number;
      storage: number;
      upload: number;
    };
    billing_data: billingDataShape[];
    next_billing_date: string;
  };
  webauthn_pub_keys?: webAuthnPubKeyShape[];
  unpaidInvoice: dbInvoicesShape;
  isActive: boolean;
}
export interface webAuthnPubKeyShape {
  id: string;
  key_name: string;
  pub_key_id: string;
  last_used_date: string;
  created_at: string;
}

export interface encryptionDataShape {
  publicKey: string;
  selfHint: string;
}

export type memberRoles = 'OWNER' | 'MANAGER' | 'BILLING' | 'GENERAL_USER';

export interface memberShape {
  accountKey: string;
  name: string;
  email: string;
  avatar: string;
  role: {
    id: string;
    type: memberRoles;
  };
  isActive: boolean;
  lastUpload: number;
}

export interface privateAccountShape extends accountShape {
  enabled2Fa: {
    email: boolean;
    ga: boolean;
  };
  requestInvite: boolean;
  loggedIn: boolean;
  accessToken: string;
  admin?: {
    rates?: {
      upload: number;
      download: number;
      storage: number;
      markup: number;
    };
    users?: any;
    pending?: any;
    nodes?: any;
    organizations?: any;
    orgInvites?: any;
  };
  newOrganization?: {
    owner_name: string;
    owner_email: string;
    account_key: string;
    stripe_id: string;
  };
  organizations?: any[];
  isOnline: boolean;
}

export interface organizationShape {
  name: string;
  key: string;
  role: string;
  is_active: boolean;
  has_credit_card: boolean;
}

export interface organizationAccountShape extends accountShape {
  members?: memberShape[];
  invites?: accountShape[];
}

export interface userStateShape {
  data?: privateAccountShape;
  isSwitchingAccount: boolean;
  currentOrganization?: organizationAccountShape | null;
  switchingError?: string | null;
}

export enum Notifications {
  NEW_INVOICE = 'new_invoice',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_SUCCEED = 'payment_succeed',
  PAYMENT_UNAVAILABLE = 'payment_unavailable',
  THRESHOLD_UNREACHED = 'threshold_unreached',
  ACCOUNT_FROZEN = 'account_frozen',
  ACCOUNT_SUSPENDED = 'account_suspended',
  WELCOME = 'welcome',
  UPGRADED_TO_NODE_HANDLER = 'upgraded_to_node_handler',
  CREATE_NEW_NODE = 'create_new_node',
  NODE_IS_OFFLINE = 'node_is_offline',
  NODE_IS_ONLINE = 'node_is_online',
  NODE_IS_HEALTHY = 'node_is_healthy',
  NODE_IS_NOT_HEALTHY = 'node_is_not_healthy',
  NEW_SHARED_FILE = 'new_shared_file',
  ORG_WELCOME = 'org_welcome',
}

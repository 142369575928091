import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { Menu } from '../../components/Menu';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { callApi } from '../../functions/callApi';
import { Button } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { ServerError } from '../../components/ServerError';
import { SideDataGrid } from '../../components/SideDataGrid';
import { SideDataGridItem } from '../../components/SideDataGridItem';
import { isIpAddress } from '../../functions/validators';
import { epochToDate } from '../../functions/formatters';
import { useMainNav } from '../../hooks/useMainNav';

import online from '../../images/online.svg';
import offline from '../../images/offline.svg';
import enabled from '../../images/enabled.svg';
import disabled from '../../images/disabled.svg';
import healthy from '../../images/healthy.svg';
import unhealthy from '../../images/unhealthy.svg';

function Nodes(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenRight, setIsOpenRight] = useState<boolean>(false);
  const [ipAddress, setIpAddress] = useState<string>('');
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [addDisabled, setAddDisabled] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [selectedNode, setSelectedNode] = useState<any>();
  const [disableLoading, setDisableLoading] = useState<boolean>(false);
  const [offlineLoading, setOfflineLoading] = useState<boolean>(false);
  const [deleteNodeLoading, setDeleteNodeLoading] = useState<boolean>(false);
  const [rsaPubKey, setRsaPubKey] = useState<string>('');
  const [peerId, setPeerId] = useState<string>('');
  const [nodeName, setNodeName] = useState<string>('');
  const navList = useMainNav();

  useEffect(() => {
    setAddDisabled(!peerId || !ipAddress || !nodeName || !rsaPubKey);
  }, [peerId, ipAddress, nodeName, rsaPubKey]);

  const doUpdateNodes = async (): Promise<void> => {
    setServerError('');
    let url: string = '';
    if (userState.data?.userRole === 'SUPERADMIN') {
      url = `storage-node/info`;
    } else {
      url = `storage-node/info/${
        userState.data?.isActive
          ? userState.data?.accountKey
          : userState.currentOrganization?.accountKey
      }`;
    }
    const response = await callApi<any>(url);
    if (response.status === 200) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          storageNodes: response,
        },
      }));
    } else setServerError(response.message);
  };

  useEffect(() => {
    doUpdateNodes();
  }, []);

  const addNode = async (): Promise<void> => {
    try {
      setServerError('');
      if (!isIpAddress(ipAddress)) {
        setServerError('Invalid IP address');
        return;
      }
      setAddLoading(true);
      const body = JSON.stringify({
        node_name: nodeName,
        public_v4: ipAddress,
        rsa_pub_key: rsaPubKey,
        peer_id: peerId,
      });
      const response = await callApi<any>(`storage-node/add`, 'POST', body);
      if (response.account_key) {
        setIsOpen(false);
        doUpdateNodes();
      }
      (response.status === 400 || response.status === 405) &&
        setServerError(response.message || 'Something went wrong A.');
    } catch (error: any) {
      if (error != 'SyntaxError: Unexpected end of JSON input') {
        setServerError(error.message || 'Something went wrong B.');
      }
    } finally {
      setAddLoading(false);
    }
  };

  const disableNode = async (isEnabled: boolean): Promise<void> => {
    try {
      setDisableLoading(true);
      const body = JSON.stringify({
        account_key: userState.data?.isActive
          ? userState.data?.accountKey
          : userState.currentOrganization?.accountKey,
        node_id: selectedNode.id,
        data: { is_enabled: !isEnabled },
      });
      await callApi<any>(`storage-node`, 'PATCH', body);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setDisableLoading(false);
      setIsOpenRight(false);
      doUpdateNodes();
    }
  };

  const offlineNode = async (isOnline: boolean): Promise<void> => {
    try {
      setOfflineLoading(true);
      const body = JSON.stringify({
        account_key: userState.data?.accountKey,
        node_id: selectedNode.id,
        data: { is_online: !isOnline },
      });
      await callApi<any>(`storage-node`, 'PATCH', body);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setOfflineLoading(false);
      setIsOpenRight(false);
      doUpdateNodes();
    }
  };

  const deleteNode = async (): Promise<void> => {
    try {
      setDeleteNodeLoading(true);
      const body = JSON.stringify({
        node_id: selectedNode.id,
        changedSettings: { is_deleted: true },
      });
      await callApi<any>(`storage-node/delete`, 'POST', body);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setDeleteNodeLoading(false);
      setIsOpenRight(false);
      doUpdateNodes();
    }
  };

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading title='Add Node' subtitle='Add a new Storage Node.' />
            <ServerError error={serverError} />
            <TextInput
              type='text'
              name='Node name'
              value={nodeName}
              setValue={setNodeName}
              label
            />
            <TextInput
              type='text'
              name='IP Address'
              value={ipAddress}
              setValue={setIpAddress}
              placeHolder='192.168.1.1'
              label
            />
            <TextInput
              type='textarea'
              name='RSA public key of the Node'
              value={rsaPubKey}
              setValue={setRsaPubKey}
              label
            />
            <TextInput
              type='text'
              name='IPFS peer id of the Node'
              value={peerId}
              setValue={setPeerId}
              label
            />

            <Button
              name='Add'
              click={addNode}
              loading={addLoading}
              disabled={addDisabled}
            />
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isOpenRight}
          setIsOpen={setIsOpenRight}
          position='RIGHT'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title={selectedNode?.hostname}
              subtitle='You are editing this nodes settings.'
            />
            <div>
              <br></br>
              <br></br>
              <>
                <SideDataGrid description='Info'>
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Public v4: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.public_v4}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Account Key: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.account_key}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Node Key: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.id}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Storage Available (GB):',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.storage_avail}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Upload Connection Speed (Mbps):',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${
                            selectedNode?.upload_connection_speed || 0
                          }`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Download Connection Speed (Mbps):',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${
                            selectedNode?.download_connection_speed || 0
                          }`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Bandwidth (Mbps): ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.bandwidth}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />

                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Created: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${epochToDate(selectedNode?.created, true)}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                </SideDataGrid>

                <SideDataGrid description='Status'>
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'RAM Load (GB): ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.ram_load}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'CPU Load: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.cpu_load}%`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Deleted: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.is_deleted}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Enabled: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.is_enabled}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Healthy: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.is_healthy}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Online: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.is_online}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Last Seen: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${epochToDate(
                            selectedNode?.last_seen,
                            true
                          )}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                </SideDataGrid>
                <SideDataGrid description='Location'>
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'City: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.city}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Continent: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.continent}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Country: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.country}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />

                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Latitude: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.latitude}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Longitude: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.longitude}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                </SideDataGrid>

                <Button
                  name={
                    selectedNode?.is_enabled ? 'Disable Node' : 'Enable Node'
                  }
                  click={(): any => disableNode(selectedNode?.is_enabled)}
                  loading={disableLoading}
                />
                {userState.data?.userRole === 'SUPERADMIN' && (
                  <Button
                    name={
                      selectedNode?.is_online
                        ? 'Take node offline'
                        : 'Take node online'
                    }
                    click={(): any => offlineNode(selectedNode?.is_online)}
                    loading={offlineLoading}
                  />
                )}
                <Button
                  name='Delete Node'
                  click={deleteNode}
                  loading={deleteNodeLoading}
                  color='RED'
                />
              </>
            </div>
          </div>
        </SideMenu>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={navList} selected='Storage Nodes' />
          </div>
          <Card>
            <SettingsItem
              title='Nodes'
              subtitle='You can add and remove nodes from your account'
              button='Add Node'
              buttonClick={(): void => setIsOpen(true)}
            >
              {userState?.data?.storageNodes?.length ? (
                <Card>
                  <>
                    {userState?.data?.storageNodes?.length &&
                      userState?.data?.storageNodes?.map(
                        (node: any, index: any) => (
                          <div key={index} style={{ cursor: 'pointer' }}>
                            <SettingsItem
                              inner
                              title={
                                `${node.node_name || 'Error'} ${
                                  node.country || ''
                                } @ ${node.public_v4 || ''}` || ''
                              }
                              subtitle={
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto auto auto',
                                    marginTop: '12px',
                                    maxWidth: '200px',
                                  }}
                                >
                                  <span>
                                    {node.is_enabled ? (
                                      <img src={enabled} />
                                    ) : (
                                      <img src={disabled} />
                                    )}
                                  </span>
                                  <span>
                                    {node.is_healthy ? (
                                      <img src={healthy} />
                                    ) : (
                                      <img src={unhealthy} />
                                    )}
                                  </span>
                                  <span>
                                    {node.is_online ? (
                                      <img src={online} />
                                    ) : (
                                      <img src={offline} />
                                    )}
                                  </span>
                                </div>
                              }
                              border
                            >
                              <Button
                                name='Inspect'
                                click={(): any => {
                                  setIsOpenRight(true);
                                  setSelectedNode(node);
                                }}
                              />
                            </SettingsItem>
                          </div>
                        )
                      )}
                  </>
                </Card>
              ) : serverError ? (
                <div className='Users--none'>{serverError}</div>
              ) : (
                <div className='Users--none'>You don't have any nodes yet</div>
              )}
            </SettingsItem>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Nodes;

import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { TextInput } from './TextInput';
import { isEmail } from '../functions/validators';
import { callApi } from '../functions/callApi';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { Button } from './Button';
import { ServerError } from './ServerError';

export interface PropsShape {
  organizationKey: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  getActiveMember?: () => void;
  getPendingInvites?: () => void;
}

export interface OrganizationShape {
  name: string;
}

const MIN_SEARCH_LENGTH = 2;
const MIN_EMAIL_LENGTH = 5;

const AddMemberSection = ({
  organizationKey,
  setIsOpen,
  getActiveMember,
  getPendingInvites,
}: PropsShape): ReactElement => {
  const { userState } = useGlobalUserState();
  const [inputValue, setInputValue] = useState<string>('');
  const [userList, setUserList] = useState<[]>([]);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [accountKeyToAdd, setAccountKeyToAdd] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const getList = async (search: string = ''): Promise<void> => {
    const list = await callApi<any>(
      `accounts/advanced-search?offset=0&limit=25`,
      'POST',
      JSON.stringify({ searchParam: search, isAddMember: true })
    );

    setUserList(
      list.results.filter(
        (item: any) => item.account_key !== userState.data?.accountKey
      )
    );
  };

  const handleAddMember = async (): Promise<void> => {
    try {
      const body = email
        ? JSON.stringify({
            organization_key: organizationKey,
            email: email,
            name: name,
          })
        : JSON.stringify({
            organization_key: organizationKey,
            account_key: accountKeyToAdd,
          });
      const res = await callApi<any>(`organizations/add_member`, 'POST', body);
      if (res && res.status === 200) {
        setEmail('');
        setInputValue('');
        if (setIsOpen) {
          setIsOpen(false);
        }
        if (getActiveMember) {
          await getActiveMember();
        }
        if (getPendingInvites) {
          await getPendingInvites();
        }
      } else if (res && res.message) {
        setErrorMessage(res.message);
      }
    } catch (err) {
      setErrorMessage('Something went wrong. Please try again.');
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      if (
        inputValue &&
        inputValue.length > MIN_SEARCH_LENGTH &&
        inputValue.startsWith('@')
      ) {
        await getList(inputValue.substring(1));
      } else if (
        inputValue &&
        inputValue.length > MIN_EMAIL_LENGTH &&
        isEmail(inputValue)
      ) {
        setUserList([]);
        setEmail(inputValue);
      } else {
        setUserList([]);
        setEmail('');
      }
    })();
  }, [inputValue]);

  return (
    <>
      <TextInput
        placeHolder='Search by username, full name or email'
        name='Email or username'
        value={inputValue}
        setValue={setInputValue}
        search
        label
      />
      {email && (
        <>
          <TextInput
            placeHolder='Who do you want to send an invitation to?'
            name='Name'
            value={name}
            setValue={setName}
            label
          />
          <div className='AddMemberModal--email'>
            <div className='AddMemberModal--email-icon'></div>
            <div className='AddMemberModal--email-wrapper'>
              <div className='AddMemberModal--email-text'>{email}</div>
              <div className='AddMemberModal--email-subtext'>
                invite to this organisation
              </div>
            </div>
          </div>
        </>
      )}

      {userList && userList.length > 0 && (
        <div className='AddMemberModal--users'>
          {userList.map((user: any) => (
            <div
              className={`AddMemberModal--user${
                accountKeyToAdd && accountKeyToAdd === user.account_key
                  ? ' selected'
                  : ''
              }`}
              key={user.account_key}
              onClick={(): void => {
                setAccountKeyToAdd(user.account_key);
              }}
            >
              <div className='AddMemberModal--user-avatar'></div>
              <div className='AddMemberModal--user-wrapper'>
                <div className='AddMemberModal--user-wrapper-title'>
                  {user.owner_name}
                </div>
                <div className='AddMemberModal--user-wrapper-subtitle'>
                  {user.owner_email}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <ServerError error={errorMessage} />
      <Button
        name='Add Member'
        click={handleAddMember}
        disabled={!(email && name) && !accountKeyToAdd}
      />
    </>
  );
};

export { AddMemberSection };

import { callApi } from './callApi';
import { FileDecryptionParamsShape } from '../hooks/useFilesTransfer';
import useEncryption from '../hooks/useEncryption';
import { Dispatch, SetStateAction } from 'react';
import { FileShape } from '../interfaces/files';
const { asyncDecrypt, transformStringToUint8Array, syncDecrypt, asyncEncrypt } =
  useEncryption();

const getPrivateKey = async (
  masterKey: string,
  handleError: Dispatch<SetStateAction<string>>,
  organizationKey?: string
): Promise<ArrayBuffer> => {
  try {
    const url = organizationKey
      ? `encryption/private-key?organization_key=${organizationKey}`
      : `encryption/private-key`;
    const res = await callApi<any>(url, 'GET');
    if (res.status === 200) {
      const decryptedKey = await syncDecrypt(
        transformStringToUint8Array(res.rsa_private_key),
        masterKey,
        res.salt,
        res.iv
      );
      return decryptedKey;
    } else {
      handleError(res.message);
      throw new Error(res.message);
    }
  } catch (e) {
    handleError('Password is not correct');
    throw new Error('Password is not correct');
  }
};

const getFilePassword = async (
  privateKey: ArrayBuffer,
  file: FileShape,
  handleError: Dispatch<SetStateAction<string>>,
  organizationKey?: string
): Promise<FileDecryptionParamsShape> => {
  try {
    const url = organizationKey
      ? `encryption/file/encryption-params/${
          file.key
        }/${!!file.shared}?organization_key=${organizationKey}`
      : `encryption/file/encryption-params/${file.key}/${!!file.shared}`;
    const res = await callApi<any>(url, 'GET');
    if (res.status === 200) {
      const encryptedPassword = await asyncDecrypt(
        transformStringToUint8Array(res.password),
        new Uint8Array(privateKey)
      );
      return {
        password: new TextDecoder('utf-8').decode(
          new Uint8Array(encryptedPassword)
        ),
        iv: res.iv,
        salt: res.salt,
      };
    } else {
      throw new Error(res.message);
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    handleError(e.message || e);
    throw new Error(e.message || e);
  }
};

const encryptPasswordToShare = async (
  fileDecryptionParams: FileDecryptionParamsShape,
  email: string,
  handleError: Dispatch<SetStateAction<string>>
): Promise<Uint8Array> => {
  try {
    const userSharePubKeyRes = await callApi<any>(
      `encryption/public-key/${email}`,
      'GET'
    );
    if (userSharePubKeyRes.status === 200) {
      const encryptedPassword = await asyncEncrypt(
        new TextEncoder().encode(fileDecryptionParams.password).buffer,
        userSharePubKeyRes.rsa_pub_key
      );
      return new Uint8Array(encryptedPassword);
    } else {
      handleError(userSharePubKeyRes.message);
      throw new Error(userSharePubKeyRes.message);
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e.message);
    handleError(e.message);
    throw new Error(e.message);
  }
};

export { getPrivateKey, getFilePassword, encryptPasswordToShare };

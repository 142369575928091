import React, { ReactElement, useState, useEffect } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { Menu } from '../../components/Menu';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { callApi } from '../../functions/callApi';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { QRCodeSVG } from 'qrcode.react';
import ChangePasswordLoggedIn from '../../components/ChangePasswordLoggedIn';
import { TextInput } from '../../components/TextInput';
import { ServerError } from '../../components/ServerError';
import { CheckBox } from '../../components/CheckBox';
import { useMainNav } from '../../hooks/useMainNav';

interface faProps {
  email: boolean;
  ga: boolean;
}

interface toggleErrorProps {
  email: string;
  ga: string;
}

enum faTypes {
  GA = 'ga',
  Email = 'email',
}

function Security(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  const [resetCodeLoading, setResetCodeLoading] = useState<boolean>(false);
  const [isOpenTwoFA, setIsOpenTwoFA] = useState<faProps>({
    email: false,
    ga: false,
  });
  const [isOpenChangePassword, setIsOpenChangePassword] =
    useState<boolean>(false);
  const [loading2Fa, setLoading2Fa] = useState<faProps>({
    email: false,
    ga: false,
  });
  const [send2FaTokenLoading, setSend2FaTokenLoading] =
    useState<boolean>(false);
  const [secret2Fa, setSecret2Fa] = useState<boolean>(false);
  const [digitTOTP, setDigitTOTP] = useState<string>('');
  const [toggleError, setToggleError] = useState<toggleErrorProps>({
    email: '',
    ga: '',
  });
  const [isValidTOTP, setIsValidTOTP] = useState<boolean>(false);
  const [isScanedQR, setIsScanedQR] = useState<boolean>(false);
  const [isReadEmail, setIsReadEmail] = useState<boolean>(false);
  const [device, setDevice] = useState<'Android' | 'iOS' | 'Unknown'>(
    'Unknown'
  );
  const [isSendEmailDisabled, setIsSendEmailDisabled] =
    useState<boolean>(false);
  const [timeoutValue, setTimeoutValue] = useState<number>(0);
  const [isOpenNodeModal, setIsOpenNodeModal] = useState<boolean>(false);
  const [serverAddNodeError, setServerAddNodeError] = useState<string>('');
  const [ipAddress, setIpAddress] = useState<string>('');
  const [rsaPubKey, setRsaPubKey] = useState<string>('');
  const [peerId, setPeerId] = useState<string>('');
  const [nodeName, setNodeName] = useState<string>('');
  const [loadingNode, setLoadingNode] = useState<boolean>(false);
  const [addDisabled, setAddDisabled] = useState<boolean>(false);
  const navList = useMainNav();

  useEffect(() => {
    setAddDisabled(!ipAddress || !rsaPubKey || !peerId);
  }, [ipAddress, rsaPubKey, peerId]);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || '';

    if (/android/i.test(userAgent)) {
      setDevice('Android');
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !/windows phone/i.test(userAgent)
    ) {
      setDevice('iOS');
    } else {
      setDevice('Unknown');
    }
  }, []);

  useEffect(() => {
    if (digitTOTP && /^\d{6}$/.test(digitTOTP)) {
      setIsValidTOTP(true);
    } else {
      setIsValidTOTP(false);
    }
  }, [digitTOTP]);

  useEffect(() => {
    if (!isOpenTwoFA.ga) {
      setIsScanedQR(false);
    }
    if (!isOpenTwoFA.email) {
      setIsReadEmail(false);
    }
  }, [isOpenTwoFA]);

  const renderDownloadLink = (): any => {
    if (device === 'Android') {
      return (
        <a
          href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
          target='_blank'
          rel='noopener noreferrer'
        >
          Download Google Authenticator for Android
        </a>
      );
    } else if (device === 'iOS') {
      return (
        <a
          href='https://apps.apple.com/app/google-authenticator/id388497605'
          target='_blank'
          rel='noopener noreferrer'
        >
          Download Google Authenticator for iOS
        </a>
      );
    } else {
      return;
    }
  };

  const sendResetCodeEmail = async (): Promise<void> => {
    setResetCodeLoading(true);
    await callApi<any>(
      `account/reset-password`,
      'POST',
      JSON.stringify({ email: userState?.data?.email })
    );
    setResetCodeLoading(false);
  };

  const get2faSecret = async (): Promise<void> => {
    setLoading2Fa((prevState): any => ({
      ...prevState,
      ga: true,
    }));
    const res = await callApi<any>(`auth/get-2fa-secret`, 'GET');
    if (res.secret) {
      setSecret2Fa(res.secret);
      setIsOpenTwoFA((prevState): any => ({
        ...prevState,
        ga: true,
      }));
    }
    setLoading2Fa((prevState): any => ({
      ...prevState,
      ga: false,
    }));
  };

  const sendTwoFAInfoEmail = async (): Promise<void> => {
    try {
      setLoading2Fa((prevState): any => ({
        ...prevState,
        email: true,
      }));
      if (!isSendEmailDisabled) {
        const result = await callApi<any>(
          `auth/send-2fa-info-email`,
          'POST',
          JSON.stringify({
            email: userState.data?.email,
          })
        );
        if (result.status === 200) {
          setIsSendEmailDisabled(true);
          setTimeoutValue(60);
        } else if (result.status === 403) {
          setIsSendEmailDisabled(true);
          setTimeoutValue(Math.round(result.timeout));
        } else if (result && result.message)
          setToggleError((prevState): any => ({
            ...prevState,
            email: result.message,
          }));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading2Fa((prevState): any => ({
        ...prevState,
        email: false,
      }));
      if (!isOpenTwoFA.email) {
        setIsOpenTwoFA((prevState): any => ({
          ...prevState,
          email: true,
        }));
      }
    }
  };

  const handle2fa = async (type: faTypes): Promise<void> => {
    setLoading2Fa((prevState): any => ({
      ...prevState,
      [type]: true,
    }));

    const body = JSON.stringify(
      userState.data?.enabled2Fa[type]
        ? {
            is_enable_2fa: !userState.data?.enabled2Fa[type],
            token: digitTOTP,
            type_2fa: type,
          }
        : {
            is_enable_2fa: !userState.data?.enabled2Fa[type],
            type_2fa: type,
          }
    );

    const res = await callApi<any>(`auth/toggle-2fa `, 'POST', body);

    if (res.status === 200) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          enabled2Fa: {
            ...res.types_2fa,
          },
        },
      }));
      setIsOpenTwoFA((prevState) => ({
        ...prevState,
        [type]: false,
      }));
    } else {
      if (res && res.message) {
        setToggleError((prevState): any => ({
          ...prevState,
          [type]: res.message,
        }));
      }
    }
    setLoading2Fa((prevState): any => ({
      ...prevState,
      [type]: false,
    }));
  };

  const send2FAToken = async (): Promise<void> => {
    try {
      setSend2FaTokenLoading(true);
      const result = await callApi<any>(
        'auth/send-token-by-email',
        'POST',
        JSON.stringify({ email: userState.data?.email })
      );
      if (result.status !== 200) {
        setToggleError((prevState): any => ({
          ...prevState,
          email: result.message,
        }));
      } else {
        setToggleError((prevState): any => ({
          ...prevState,
          email: '',
        }));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSend2FaTokenLoading(false);
    }
  };

  const getDisableInput = (type: faTypes): any => {
    if (userState.data?.enabled2Fa[type]) {
      return (
        <TextInput
          type='number'
          name='digitTOTP'
          value={digitTOTP}
          setValue={setDigitTOTP}
          focus
        />
      );
    }
    return null;
  };

  const addNode = async (): Promise<void> => {
    try {
      setLoadingNode(true);
      setServerAddNodeError('');
      const body = JSON.stringify({
        node_name: nodeName,
        public_v4: ipAddress,
        rsa_pub_key: rsaPubKey,
        peer_id: peerId,
      });

      const res = await callApi<any>(`account/upgrade-to-node`, 'POST', body);

      if (res.status === 200) {
        setUserState((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            userRole: 'NODE_HANDLER',
          },
        }));
        setIsOpenNodeModal(false);
      } else {
        setServerAddNodeError(res?.message);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setLoadingNode(false);
    } finally {
      setLoadingNode(false);
    }
  };

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    let intervalId: NodeJS.Timeout | undefined;

    if (timeoutValue > 0) {
      intervalId = setInterval(() => {
        setTimeoutValue((prevState) => prevState - 1);
      }, 1000);
    } else setIsSendEmailDisabled(false);

    return () => {
      if (intervalId !== undefined) clearInterval(intervalId);
    };
  }, [timeoutValue]);

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={navList} selected='Security' />
          </div>

          <Card>
            <SettingsItem title='Password' subtitle='Change your password'>
              <Card>
                <SettingsItem
                  inner
                  title='Open a popup'
                  subtitle='We will display a popup for changing the password'
                >
                  <Button
                    name='Open'
                    margin={0}
                    click={(): void => setIsOpenChangePassword(true)}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem>
            <SettingsItem title='Password' subtitle='Reset your password'>
              <Card>
                <SettingsItem
                  inner
                  title='Request Reset Code'
                  subtitle='We will send you an email with a reset code'
                >
                  <Button
                    name='Send'
                    margin={0}
                    click={sendResetCodeEmail}
                    loading={resetCodeLoading}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem>
            <SettingsItem title='2FA' subtitle='Secure your account'>
              <Card>
                <SettingsItem
                  inner
                  title='Google Authenticator'
                  subtitle={
                    userState.data?.enabled2Fa.ga
                      ? 'To disable two-factor authentication, enter 6-Digit TOTP and click the Disable button.'
                      : 'Enable 2FA with Google Authenticator'
                  }
                >
                  <ServerError error={toggleError.ga} />
                  {getDisableInput(faTypes.GA)}
                  <Button
                    name={
                      userState.data?.enabled2Fa.ga ? 'Disable' : 'Show QR Code'
                    }
                    margin={0}
                    click={
                      userState.data?.enabled2Fa.ga
                        ? (): any => handle2fa(faTypes.GA)
                        : get2faSecret
                    }
                    loading={loading2Fa.ga}
                    disabled={
                      (userState.data?.enabled2Fa.ga && !isValidTOTP) ||
                      userState.data?.enabled2Fa.email
                    }
                  />
                </SettingsItem>
              </Card>
              <Card>
                <SettingsItem
                  inner
                  title='Email Authenticator'
                  subtitle='Enable 2FA with email'
                >
                  <ServerError error={toggleError.email} />
                  {getDisableInput(faTypes.Email)}
                  <Button
                    name={
                      userState.data?.enabled2Fa?.email
                        ? 'Disable'
                        : 'Show Info Popup'
                    }
                    margin={0}
                    click={
                      userState.data?.enabled2Fa.email
                        ? (): any => handle2fa(faTypes.Email)
                        : sendTwoFAInfoEmail
                    }
                    loading={loading2Fa.email}
                    disabled={
                      (userState.data?.enabled2Fa.email && !isValidTOTP) ||
                      userState.data?.enabled2Fa.ga
                    }
                  />
                  {userState.data?.enabled2Fa?.email && (
                    <Button
                      name='Send Token'
                      margin={6}
                      click={send2FAToken}
                      loading={send2FaTokenLoading}
                    />
                  )}
                </SettingsItem>
              </Card>
            </SettingsItem>
            <>
              {userState.data?.userRole === 'CUSTOMER' &&
                userState.currentOrganization?.userRole !== 'NODE_HANDLER' && (
                  <SettingsItem title='Node' subtitle='Become Node Handler'>
                    <Card>
                      <SettingsItem
                        inner
                        title='Storage Node'
                        subtitle='Add your first Node to become a Strage Node Handler'
                      >
                        <Button
                          name='Become Handler'
                          margin={0}
                          click={(): void => setIsOpenNodeModal(true)}
                          loading={resetCodeLoading}
                        />
                      </SettingsItem>
                    </Card>
                  </SettingsItem>
                )}
            </>
          </Card>
        </div>
      </div>
      <SideMenu
        isOpen={isOpenTwoFA.ga}
        setIsOpen={(): any =>
          setIsOpenTwoFA((prevState) => ({
            ...prevState,
            ga: false,
          }))
        }
        position='CENTER'
        width='REGULAR'
      >
        <div style={{ padding: '24px' }}>
          <Heading
            title='Setup 2FA'
            subtitle='Scan the QR code below with Google Authenticator'
          />
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <QRCodeSVG
              value={`otpauth://totp/${userState?.data?.name}?secret=${secret2Fa}&issuer=dStor&algorithm=SHA1&digits=6&period=30
`}
            />
            <p style={{ paddingTop: '24px' }}>
              Or enter the setup key manually{' '}
              <span style={{ fontSize: '11px', fontWeight: 'bold' }}>
                {secret2Fa}{' '}
              </span>
            </p>
            <p> {renderDownloadLink()}</p>
          </div>
          <CheckBox
            label='I scanned QR code or entered the setup key manually'
            id='scanedQR'
            value={isScanedQR}
            setValue={setIsScanedQR}
          />
          <Button
            name='Enable'
            margin={0}
            click={(): any => handle2fa(faTypes.GA)}
            loading={loading2Fa.ga}
            disabled={!isScanedQR}
          />
        </div>
      </SideMenu>
      <SideMenu
        isOpen={isOpenTwoFA.email}
        setIsOpen={(): any =>
          setIsOpenTwoFA((prevState) => ({
            ...prevState,
            email: false,
          }))
        }
        position='CENTER'
        width='REGULAR'
      >
        <div style={{ padding: '24px' }}>
          <Heading
            title='Setup 2FA'
            subtitle='Check your email for information about Two-Factor Authentication'
          />
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <p style={{ fontSize: 14 }}>
              If you did not receive the email, click{' '}
              <span
                onClick={sendTwoFAInfoEmail}
                style={{ color: '#2581e3', cursor: 'pointer' }}
              >
                send again{' '}
              </span>
              {isSendEmailDisabled && <span>after {timeoutValue} seconds</span>}
            </p>
          </div>
          <CheckBox
            label='I received an email and have read it'
            id='readEmail'
            value={isReadEmail}
            setValue={setIsReadEmail}
          />
          <Button
            name='Enable'
            margin={0}
            click={(): any => handle2fa(faTypes.Email)}
            loading={loading2Fa.email}
            disabled={!isReadEmail}
          />
        </div>
      </SideMenu>
      <SideMenu
        isOpen={isOpenChangePassword}
        setIsOpen={setIsOpenChangePassword}
        position='CENTER'
        width='REGULAR'
      >
        <ChangePasswordLoggedIn setIsOpen={setIsOpenChangePassword} />
      </SideMenu>
      <SideMenu
        isOpen={isOpenNodeModal}
        setIsOpen={setIsOpenNodeModal}
        position='CENTER'
        width='REGULAR'
      >
        <div className='Header--newfolder'>
          <Heading title='Add Node' subtitle='Add the first storage node.' />
          <ServerError error={serverAddNodeError} />
          <TextInput
            type='text'
            name='Node name'
            value={nodeName}
            setValue={setNodeName}
            label
          />
          <TextInput
            type='text'
            name='IP Address'
            value={ipAddress}
            setValue={setIpAddress}
            placeHolder='192.168.1.1'
            label
          />
          <TextInput
            type='textarea'
            name='RSA public key of the Node'
            value={rsaPubKey}
            setValue={setRsaPubKey}
            label
          />
          <TextInput
            type='text'
            name='IPFS peer id of the Node'
            value={peerId}
            setValue={setPeerId}
            label
          />

          <Button
            name='Add'
            click={addNode}
            loading={loadingNode}
            disabled={addDisabled}
          />
        </div>
      </SideMenu>
    </div>
  );
}

export default Security;

import { Header } from '../../components/Header';
import React, { ReactElement, useEffect, useState } from 'react';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { ServerError } from '../../components/ServerError';
import ButtonInput from '../../components/ButtonInput';
import { Menu } from '../../components/Menu';
import { Card } from '../../components/Card';
import { SettingsItem } from '../../components/SettingsItem';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { callApi } from '../../functions/callApi';
import { Copy } from '../../components/Copy';
import useEncryption from '../../hooks/useEncryption';
import { useMainNav } from '../../hooks/useMainNav';

interface selectedInputsShape {
  create: boolean;
  phrase: boolean;
}

interface disabledShape {
  create: boolean;
  delete: boolean;
}

function Encryption(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenMasterKeyModal, setIsOpenMasterKeyModal] =
    useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [selectedInputs, setSelectedInputs] = useState<selectedInputsShape>({
    create: false,
    phrase: false,
  });
  const [addDisabled, setAddDisabled] = useState<disabledShape>({
    create: true,
    delete: true,
  });
  const [masterKeyLoading, setMasterKeyLoading] = useState<boolean>(false);
  const [masterKey, setMasterKey] = useState<string>('');
  const [selfHint, setSelfHint] = useState<string>('');
  const {
    syncEncrypt,
    transformStringToUint8Array,
    transformUint8ArrayToString,
    syncDecrypt,
  } = useEncryption();
  const navList = useMainNav();

  const createKeyPair = async (): Promise<void> => {
    try {
      setServerError('');
      setMasterKeyLoading(true);
      const keyPair = await window.crypto.subtle.generateKey(
        {
          name: 'RSA-OAEP',
          modulusLength: 2048,
          publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
          hash: 'SHA-256',
        },
        true,
        ['encrypt', 'decrypt']
      );
      const salt = window.crypto.getRandomValues(new Uint8Array(16));
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      const exportedPrivateKey: ArrayBuffer =
        await window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey);
      const encryptedPrivateKey = await syncEncrypt(
        exportedPrivateKey,
        masterKey,
        salt,
        iv
      );
      const saltString = transformUint8ArrayToString(salt);
      const encryptedPrivateKeyString = transformUint8ArrayToString(
        new Uint8Array(encryptedPrivateKey)
      );
      const ivString = transformUint8ArrayToString(iv);
      const exportedPublicKey = await window.crypto.subtle.exportKey(
        'spki',
        keyPair.publicKey
      );
      const publicKeyString = transformUint8ArrayToString(
        new Uint8Array(exportedPublicKey)
      );
      const res = await callApi<any>(
        `encryption/save-key-pair`,
        'POST',
        JSON.stringify({
          organizationKey: userState.currentOrganization?.accountKey || null,
          privateKey: encryptedPrivateKeyString,
          publicKey: publicKeyString,
          encryptionParams: {
            salt: saltString,
            iv: ivString,
          },
          selfHint,
        })
      );
      if (res.status === 200) {
        setIsOpenMasterKeyModal(false);
        if (userState.currentOrganization?.accountKey) {
          setUserState((prevState: any) => ({
            ...prevState,
            currentOrganization: {
              ...prevState.currentOrganization,
              encryption: {
                publicKey: res.rsa_pub_key,
                selfHint: res.self_hint,
              },
            },
          }));
        } else {
          setUserState((prevState: any) => ({
            ...prevState,
            data: {
              ...prevState.data,
              encryption: {
                publicKey: res.rsa_pub_key,
                selfHint: res.self_hint,
              },
            },
          }));
        }
        setMasterKey('');
        setSelfHint('');
      } else setServerError(res.message);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setMasterKeyLoading(false);
    }
  };

  const deleteKeyPair = async (): Promise<void> => {
    try {
      setServerError('');
      const privateKeyUrl = userState.currentOrganization?.accountKey
        ? `encryption/private-key?organization_key=${userState.currentOrganization?.accountKey}`
        : `encryption/private-key`;
      const encryptedKeyRes = await callApi<any>(privateKeyUrl, 'GET');
      if (encryptedKeyRes.status !== 200) {
        setServerError(encryptedKeyRes.message);
        throw new Error(encryptedKeyRes.message);
      }
      try {
        const encryptedPrivateKey = transformStringToUint8Array(
          encryptedKeyRes.rsa_private_key
        );
        await syncDecrypt(
          encryptedPrivateKey,
          masterKey,
          encryptedKeyRes.salt,
          encryptedKeyRes.iv
        );
      } catch (e) {
        setServerError('Password is not correct');
        throw new Error('Password is not correct');
      }
      const deleteKeyUrl = userState.currentOrganization?.accountKey
        ? `encryption/key-pair?organization_key=${userState.currentOrganization?.accountKey}`
        : `encryption/key-pair`;
      const deleteKeyPairRes = await callApi<any>(deleteKeyUrl, 'DELETE');
      if (deleteKeyPairRes.status === 200) {
        if (userState.currentOrganization?.accountKey) {
          setUserState((prevState: any) => ({
            ...prevState,
            currentOrganization: {
              ...prevState.currentOrganization,
              encryption: {},
            },
          }));
        } else {
          setUserState((prevState: any) => ({
            ...prevState,
            data: {
              ...prevState.data,
              encryption: {},
            },
          }));
        }
        setMasterKey('');
        setSelfHint('');
        setIsOpenDeleteModal(false);
      } else setServerError(deleteKeyPairRes.message);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setMasterKeyLoading(false);
    }
  };

  const getPublicKey = (): ReactElement => {
    const currentOrg = userState.currentOrganization;
    const userEncryptionKey = userState.data?.encryption?.publicKey;
    const orgEncryptionKey = currentOrg?.encryption?.publicKey;
    const userActive = userState.data?.isActive;
    const userRoleInOrg = userState.data?.organizations?.find(
      (item: any) =>
        currentOrg?.accountKey === item.organization_data.account_key
    )?.role;

    const renderSettingsItem = (key: string, isOrg: boolean): ReactElement => (
      <div key={1}>
        <SettingsItem
          inner
          title={<Copy value={key} type='BUTTON' text={key} overflow />}
          subtitle={<div>Public Key</div>}
          border
          overflow
        >
          <Button
            name='Delete'
            margin={0}
            disabled={isOrg && userRoleInOrg !== 'owner'}
            click={(): void => setIsOpenDeleteModal(true)}
          />
        </SettingsItem>
      </div>
    );

    if (orgEncryptionKey && !userActive) {
      return renderSettingsItem(orgEncryptionKey, true);
    } else if (userEncryptionKey && userActive) {
      return renderSettingsItem(userEncryptionKey, false);
    } else {
      return (
        <div className='Encryption--KeyPair'>
          You currently don't have an active encryption key
        </div>
      );
    }
  };

  const getButtonText = (): string => {
    const userRoleInOrg = userState.data?.organizations?.find(
      (item: any) =>
        userState.currentOrganization?.accountKey ===
        item.organization_data.account_key
    )?.role;
    if (
      userState.currentOrganization &&
      !userState.currentOrganization.encryption?.publicKey &&
      userRoleInOrg === 'owner'
    )
      return 'Create';
    else if (userState.currentOrganization) return '';
    else if (!userState.data?.encryption?.publicKey) return 'Create';
    else return '';
  };

  useEffect(() => {
    setAddDisabled(() => ({
      delete: !masterKey,
      create: !masterKey || !selfHint,
    }));
  }, [masterKey, selfHint]);

  useEffect(() => {
    if (selectedInputs.phrase && selectedInputs.create) {
      setIsOpen((prevState) => !prevState);
      setIsOpenMasterKeyModal((prevState) => !prevState);
      setSelectedInputs((prevState) => ({
        create: !prevState.create,
        phrase: !prevState.phrase,
      }));
    }
  }, [selectedInputs]);

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <SideMenu
          isOpen={isOpenMasterKeyModal}
          setIsOpen={setIsOpenMasterKeyModal}
          position='CENTER'
          width='REGULAR'
        >
          <div style={{ padding: '24px' }}>
            <Heading
              title='Enter master key'
              subtitle='Enter your master key and remember it. Enter a self-hint so that we can remind you of the master key if you forget'
            />
            <ServerError error={serverError} />
            <TextInput
              type='text'
              name='Master Key'
              value={masterKey}
              setValue={setMasterKey}
              label
            />
            <TextInput
              type='text'
              name='Self-hint'
              value={selfHint}
              setValue={setSelfHint}
              label
            />

            <Button
              name='Create Key Pair'
              click={createKeyPair}
              loading={masterKeyLoading}
              disabled={addDisabled.create}
            />
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isOpenDeleteModal}
          setIsOpen={setIsOpenDeleteModal}
          position='CENTER'
          width='REGULAR'
        >
          <div style={{ padding: '24px' }}>
            <Heading
              title='Enter master key'
              subtitle='Enter the master key so we can verify that you are the account owner'
            />
            <ServerError error={serverError} />
            <TextInput
              type='text'
              name='Master Key'
              value={masterKey}
              setValue={setMasterKey}
              label
              placeHolder={
                userState.currentOrganization?.encryption?.selfHint ||
                userState.data?.encryption?.selfHint
              }
            />

            <Button
              name='Delete'
              color='RED'
              click={deleteKeyPair}
              loading={masterKeyLoading}
              disabled={addDisabled.delete}
            />
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder' style={{ overflow: 'hidden' }}>
            <Heading
              title='Create'
              subtitle='To start uploading encrypted files, create and backup a new key.'
            />
            <ServerError error={serverError} />
            <ButtonInput
              label
              name='Create'
              description='This is the first time setting up encryption, lets create a new key.'
              onButtonClick={(): void =>
                setSelectedInputs((prevState) => ({
                  ...prevState,
                  create: !prevState.create,
                }))
              }
              isActive={selectedInputs.create}
            />
            {selectedInputs.create && (
              <>
                <div className='SignIn--demarcation'>
                  <span className='SignIn--demarcation-text'>from</span>
                </div>
                <ButtonInput
                  label
                  name='Phrase'
                  description='Ive got an encryption key.'
                  onButtonClick={(): void =>
                    setSelectedInputs((prevState) => ({
                      ...prevState,
                      phrase: !prevState.phrase,
                    }))
                  }
                  isActive={selectedInputs.phrase}
                />
              </>
            )}
          </div>
        </SideMenu>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={navList} selected='Encryption' />
          </div>
          <Card>
            <SettingsItem
              title='Manage your encryption key'
              subtitle='To start uploading encrypted files, create and backup a new key'
              button={getButtonText()}
              buttonClick={(): void => setIsOpen(true)}
            >
              <Card>{getPublicKey()}</Card>
            </SettingsItem>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Encryption;

import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import SideMenu from './SideMenu';
import { Heading } from './Heading';
import { TextInput } from './TextInput';
import { isEmail } from '../functions/validators';
import { callApi } from '../functions/callApi';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { Button } from './Button';
import { ServerError } from './ServerError';
import { FileShape } from '../interfaces/files';
import {
  getFilePassword,
  getPrivateKey,
  encryptPasswordToShare,
} from '../functions/encryptions';
import useEncryption from '../hooks/useEncryption';

export interface PropsShape {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  file: FileShape;
}

const MIN_SEARCH_LENGTH = 2;
const MIN_EMAIL_LENGTH = 5;

const ShareModal = ({ isOpen, setIsOpen, file }: PropsShape): ReactElement => {
  const { userState } = useGlobalUserState();
  const [inputValue, setInputValue] = useState<string>('');
  const [userList, setUserList] = useState<[]>([]);
  const [userListShared, setUserListShared] = useState<[]>([]);
  const [sharedListUpdate, setSharedListUpdate] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [accountKeyToAdd, setAccountKeyToAdd] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [isOpenMasterKeyModal, setIsOpenMasterKeyModal] =
    useState<boolean>(false);
  const [masterKey, setMasterKey] = useState<string>('');
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const [addDisabled, setAddDisabled] = useState<boolean>(true);
  const { transformUint8ArrayToString } = useEncryption();

  const getList = async (search: string = ''): Promise<void> => {
    try {
      const list = await callApi<any>(
        `accounts/advanced-search?offset=0&limit=25`,
        'POST',
        JSON.stringify({ searchParam: search, isAddMember: true })
      );
      if (list.status === 200) {
        setUserList(
          list.results.filter(
            (item: any) => item.account_key !== userState.data?.accountKey
          )
        );
      } else {
        throw new Error(list.message);
      }
    } catch (e: any) {
      setServerError(e?.message || e);
    }
  };

  const getUsersShared = async (): Promise<void> => {
    try {
      const list = await callApi<any>(`file/share/user/${file.key}`, 'GET');
      if (list.status === 200) {
        setUserListShared(list);
      } else {
        throw new Error(list.message);
      }
    } catch (e: any) {
      setServerError(e?.message || e);
    } finally {
      setSharedListUpdate(false);
    }
  };

  const handleShareFile = async (): Promise<void> => {
    try {
      setShareLoading(true);
      const privateKey: ArrayBuffer = await getPrivateKey(
        masterKey,
        setServerError,
        userState.currentOrganization?.accountKey
      );
      const fileDecryptionParams = await getFilePassword(
        privateKey,
        file,
        setServerError,
        userState.currentOrganization?.accountKey
      );
      const encryptedPassword = await encryptPasswordToShare(
        fileDecryptionParams,
        email,
        setServerError
      );
      const shareRes = await callApi<any>(
        'encryption/file/share',
        'POST',
        JSON.stringify({
          organizationKey: userState.currentOrganization?.accountKey || null,
          accountKey: accountKeyToAdd ? accountKeyToAdd : null,
          email: email ? email : null,
          fileKey: file.key,
          encryptedPassword: transformUint8ArrayToString(encryptedPassword),
        })
      );
      if (shareRes.status !== 200) {
        setServerError(shareRes.message);
      } else {
        setSharedListUpdate(true);
        setIsOpenMasterKeyModal(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setShareLoading(false);
    }
  };

  const deleteAccessToFile = async (accountKey: string): Promise<void> => {
    try {
      const res = await callApi<any>(
        'encryption/file/share',
        'DELETE',
        JSON.stringify({
          account_key: accountKey,
          file_key: file.key,
        })
      );
      if (res.status !== 200) {
        throw new Error(res.message);
      } else setSharedListUpdate(true);
    } catch (e: any) {
      setServerError(e?.message || e);
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      if (
        inputValue &&
        inputValue.length > MIN_SEARCH_LENGTH &&
        inputValue.startsWith('@')
      ) {
        await getList(inputValue.substring(1));
      } else if (
        inputValue &&
        inputValue.length > MIN_EMAIL_LENGTH &&
        isEmail(inputValue)
      ) {
        setUserList([]);
        setEmail(inputValue);
      } else {
        setUserList([]);
        setEmail('');
      }
    })();
  }, [inputValue]);

  useEffect(() => {
    setAddDisabled(!masterKey);
  }, [masterKey]);

  useEffect(() => {
    if (sharedListUpdate || isOpen) {
      getUsersShared();
    }
  }, [sharedListUpdate, isOpen]);

  return (
    <>
      <SideMenu
        isOpen={isOpen}
        setIsOpen={(): any => {
          setIsOpen(false);
          setServerError('');
        }}
        position='CENTER'
        width='REGULAR'
      >
        <div className='Header--newfolder'>
          <Heading
            title='Share File'
            subtitle='Enter the name or email of the user you want to share the file with'
          />
          <ServerError error={serverError} />
          <TextInput
            placeHolder='Search by username, full name or email'
            name='Sort by'
            value={inputValue}
            setValue={setInputValue}
            search
          />
          {email && !accountKeyToAdd && (
            <div className='AddMemberModal--email'>
              <div className='AddMemberModal--email-icon'></div>
              <div className='AddMemberModal--email-wrapper'>
                <div className='AddMemberModal--email-text'>{email}</div>
                <div className='AddMemberModal--email-subtext'>Share file</div>
              </div>
            </div>
          )}

          {userList && userList.length > 0 && (
            <div className='AddMemberModal--users'>
              {userList.map((user: any) => (
                <div
                  className={`AddMemberModal--user${
                    accountKeyToAdd && accountKeyToAdd === user.account_key
                      ? ' selected'
                      : ''
                  }`}
                  key={user.account_key}
                  onClick={(): void => {
                    setAccountKeyToAdd(user.account_key);
                    setEmail(user.owner_email);
                  }}
                >
                  <div className='AddMemberModal--user-avatar'></div>
                  <div className='AddMemberModal--user-wrapper'>
                    <div className='AddMemberModal--user-wrapper-title'>
                      {user.owner_name}
                    </div>
                    <div className='AddMemberModal--user-wrapper-subtitle'>
                      {user.owner_email}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className='ShareFile--users'>
            <div className='Heading--subtitle'>Users with access</div>
            {userListShared?.length > 0 && (
              <div className='AddMemberModal--users'>
                {userListShared.map((user: any) => (
                  <div
                    className={`ShareFile--user${
                      accountKeyToAdd && accountKeyToAdd === user.account_key
                        ? ' selected'
                        : ''
                    }`}
                    key={user.user_uuid}
                    onClick={(): void => {
                      setAccountKeyToAdd(user.account_key);
                    }}
                    style={{ borderRadius: '50px', cursor: 'unset' }}
                  >
                    <div className='AddMemberModal--user-avatar'></div>
                    <div className='AddMemberModal--user-wrapper'>
                      <div className='AddMemberModal--user-wrapper-title'>
                        {user.name}
                      </div>
                      <div className='AddMemberModal--user-wrapper-subtitle'>
                        {user.email}
                      </div>
                      <div className='AddMemberModal--user-wrapper-subtitle'></div>
                    </div>
                    <div
                      className='ShareFile--user-delete'
                      onClick={(): any => deleteAccessToFile(user.user_uuid)}
                    ></div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Button
            name='Share File'
            click={(): any => setIsOpenMasterKeyModal(true)}
            disabled={!email && !accountKeyToAdd}
          />
        </div>
      </SideMenu>
      <SideMenu
        isOpen={isOpenMasterKeyModal}
        setIsOpen={setIsOpenMasterKeyModal}
        position='CENTER'
        width='REGULAR'
      >
        <div style={{ padding: '24px' }}>
          <Heading
            title='Enter master key'
            subtitle='Enter the master key to decrypt your private key'
          />
          <ServerError error={serverError} />
          <TextInput
            type='text'
            name='Master Key'
            value={masterKey}
            setValue={setMasterKey}
            label
            placeHolder={
              userState.currentOrganization?.encryption?.selfHint ||
              userState.data?.encryption?.selfHint
            }
            search
          />

          <Button
            name='Decrypt'
            click={handleShareFile}
            loading={shareLoading}
            disabled={addDisabled}
          />
        </div>
      </SideMenu>
    </>
  );
};

export { ShareModal };

import { ReactElement, useState, useEffect } from 'react';
import {
  startRegistration,
  startAuthentication,
} from '@simplewebauthn/browser';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { Menu } from '../../components/Menu';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { callApi } from '../../functions/callApi';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { ServerError } from '../../components/ServerError';
import { AvatarPicker } from '../../components/AvatarPicker';
// import { Cookies } from 'react-cookie';

import image from '../../images/sean-anderson.jpg';
import defaultImage from '../../images/no-avatar.jpg';
// import { AddOrganizationModal } from '../../components/AddOrganizationModal';
import { AddOrganizationModal } from '../../components/AddOrganizationModal';
import { webAuthnPubKeyShape } from '../../interfaces/user';
import { epochToDate } from '../../functions/formatters';
import { useCookies } from 'react-cookie';
import { useMainNav } from '../../hooks/useMainNav';

const REG_MESSAGE = 'Registration options generated';

// TEST VAR
// const webAuthnPubKeys: webAuthnPubKeyShape[] = [
//   {
//     id: '1d3c7e77-f41b-4e8d-b6b3-23d7c3c5e6b1',
//     key_name: 'Key 1',
//     pub_key: 1234567890,
//     pub_key_id: 'base64pubkeyid1',
//     last_used_date: Date.now() - 86400000, // 1 day ago
//     created_at: Date.now() - 86400000 * 30, // 30 days ago
//     updated_at: Date.now(),
//   },
//   {
//     id: '2f4b1e98-d72f-4b8a-a2e7-3d6a7e4d8f2b',
//     key_name: 'Key 2',
//     pub_key: 9876543210,
//     pub_key_id: 'base64pubkeyid2',
//     last_used_date: Date.now() - 43200000, // 12 hours ago
//     created_at: Date.now() - 86400000 * 60, // 60 days ago
//     updated_at: Date.now(),
//   },
//   {
//     id: '3c8d9f21-e84d-4a5b-a8d1-5f7d8c9a1b2c',
//     key_name: 'Key 3',
//     pub_key: 1122334455,
//     pub_key_id: 'base64pubkeyid3',
//     last_used_date: Date.now() - 21600000, // 6 hours ago
//     created_at: Date.now() - 86400000 * 90, // 90 days ago
//     updated_at: Date.now(),
//   },
// ];

function Settings(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  // const [ setToggleLoading] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(
    userState.data?.isPreviewOn || false
  );
  const [cookie, setCookie] = useCookies(['webAuthn']);
  const [isAuthenticatedViaWebauthn, setIsAuthenticatedViaWebauthn] =
    useState<boolean>(cookie?.webAuthn ? JSON.parse(cookie?.webAuthn) : false);
  const [isAuthenticationLoading, setIsAuthenticationLoading] =
    useState<boolean>(false);
  const [authenticationError, setAuthenticationError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [profileLoading, setProfileLoading] = useState<boolean>(false);
  const [profileDisabled, setProfileDisabled] = useState<boolean>(false);
  const [editName, setEditName] = useState<string>(userState.data?.name || '');
  const [isOpenAddOrgModal, setIsOpenAddOrgModal] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<webAuthnPubKeyShape>();
  const [isOpenEditModel, setIsOpenEditModal] = useState<boolean>(false);
  const [isOpenDeleteModel, setIsOpenDeleteModal] = useState<boolean>(false);
  const [keyName, setKeyName] = useState<string>('');
  const [isGettingUserKeys, setIsGettingUserKeys] = useState<boolean>(false);
  const navList = useMainNav();

  useEffect(() => {
    const togglePreview = async (): Promise<void> => {
      await callApi<any>(
        `account/toggle-preview`,
        'PATCH',
        JSON.stringify({ is_preview_on: showPreview })
      );
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          isPreviewOn: showPreview,
        },
      }));
    };
    togglePreview();
  }, [showPreview]);

  useEffect(() => {
    if (!isOpenEditModel && !isOpenDeleteModel) {
      setServerError('');
    }
  }, [isOpenDeleteModel, isOpenEditModel]);

  useEffect(() => {
    if (isOpenEditModel && selectedKey) {
      setKeyName(selectedKey.key_name);
    }
  }, [isOpenEditModel, selectedKey]);

  const togglePreview = async (): Promise<void> => {
    // setToggleLoading(true);
    setShowPreview(!showPreview);
    // setToggleLoading(false);
  };

  const authenticateViaWebauthn = async (
    isAddNewKey?: boolean
  ): Promise<void> => {
    try {
      setAuthenticationError('');
      setIsAuthenticationLoading(true);
      const res = await callApi<any>(
        isAddNewKey
          ? `webauthn/generate-auth-options?isAddNewKey=${isAddNewKey}`
          : `webauthn/generate-auth-options?isAddNewKey=${false}`,
        'GET'
      );
      let resVerify: any = undefined;
      if (res) {
        if (!res.options && !res.success) {
          throw new Error(res.message);
        } else if (res.options && res.message === REG_MESSAGE) {
          const startRegResp = await startRegistration(res.options);
          resVerify = await callApi<any>(
            'webauthn/verify-reg',
            'POST',
            JSON.stringify(startRegResp),
            res.options.challenge
          );
        } else {
          const startAuthResp = await startAuthentication(res.options);
          resVerify = await callApi<any>(
            'webauthn/verify-auth',
            'POST',
            JSON.stringify(startAuthResp),
            res.options.challenge
          );
        }
        if (resVerify.status === 200) {
          setIsAuthenticatedViaWebauthn(resVerify.success);
          if (!resVerify.success) {
            setAuthenticationError(resVerify.message);
          } else {
            // @ts-ignore
            if (process.env.REACT_APP_FRONT_END_URL) {
              const parsedUrl = new URL(process.env.REACT_APP_FRONT_END_URL);
              const domain = parsedUrl.hostname;
              setCookie('webAuthn', 'true', {
                path: '/',
                domain: `.${domain}`,
                secure: true,
                sameSite: 'strict',
              });
              setIsGettingUserKeys(true);
            }
          }
        }
        setIsAuthenticationLoading(false);
      }
    } catch (err: any) {
      setIsAuthenticationLoading(false);
      setAuthenticationError(err.message);
    }
  };

  const saveProfile = async (): Promise<void> => {
    setServerError('');
    setProfileLoading(true);
    setProfileDisabled(false);
  };

  const editKeyName = async (): Promise<void> => {
    setServerError('');
    setProfileLoading(true);
    setProfileDisabled(false);
    const res = await callApi<any>(
      `webauthn/edit-key-name`,
      'PUT',
      JSON.stringify({ keyId: selectedKey?.pub_key_id, newKeyName: keyName })
    );
    if (res.success) {
      setIsGettingUserKeys(true);
    } else {
      if (res && res.message) {
        setServerError(res.message);
      }
    }
    setProfileLoading(false);
    setIsOpenEditModal(false);
  };

  const deleteKey = async (): Promise<void> => {
    setServerError('');
    setProfileLoading(true);
    setProfileDisabled(false);
    const res = await callApi<any>(
      `webauthn/delete-key`,
      'DELETE',
      JSON.stringify({ keyId: selectedKey?.pub_key_id })
    );
    if (res.success) {
      setIsGettingUserKeys(true);
    } else {
      if (res && res.message) {
        setServerError(res.message);
      }
    }
    setProfileLoading(false);
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    const getWebAuthnKeys = async (): Promise<void> => {
      try {
        setServerError('');
        const result = await callApi<any>('webauthn/get-webAuthn-keys', 'GET');
        if (result.status !== 200) {
          setServerError(result.message);
        } else {
          setUserState((prevState: any) => ({
            ...prevState,
            data: {
              ...prevState.data,
              webauthn_pub_keys: result.webauthn_pub_keys,
            },
          }));
        }
        const fetchAndSetEncryptionParams = async (
          isOrganization: boolean
        ): Promise<{ selfHint: any; publicKey: any } | void> => {
          const url = isOrganization
            ? `encryption/public-key/?organization_key=${userState.currentOrganization?.accountKey}`
            : `encryption/public-key/`;

          const response = await callApi<any>(url, 'GET');

          if (response.status === 200) {
            return {
              publicKey: response.rsa_pub_key,
              selfHint: response.self_hint,
            };
          }
        };

        if (!userState.data?.isActive) {
          const organizationEncryption = await fetchAndSetEncryptionParams(
            true
          );

          if (!userState.data?.encryption && organizationEncryption) {
            const userEncryption = await fetchAndSetEncryptionParams(false);

            if (userEncryption) {
              setUserState((prevState: any) => ({
                ...prevState,
                currentOrganization: {
                  ...prevState.currentOrganization,
                  encryption: organizationEncryption,
                },
                data: {
                  ...prevState.data,
                  encryption: userEncryption,
                },
              }));
            }
          } else if (organizationEncryption) {
            setUserState((prevState: any) => ({
              ...prevState,
              currentOrganization: {
                ...prevState.currentOrganization,
                encryption: organizationEncryption,
              },
            }));
          }
        } else if (!userState.data?.encryption) {
          const userEncryption = await fetchAndSetEncryptionParams(false);

          if (userEncryption) {
            setUserState((prevState: any) => ({
              ...prevState,
              data: {
                ...prevState.data,
                encryption: userEncryption,
              },
            }));
          }
        }
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        setIsGettingUserKeys(false);
      }
    };

    if (isGettingUserKeys) {
      getWebAuthnKeys();
    }
  }, [isGettingUserKeys]);

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <AddOrganizationModal
          isOpen={isOpenAddOrgModal}
          setIsOpen={setIsOpenAddOrgModal}
        />
        <SideMenu
          isOpen={isOpenEditModel}
          setIsOpen={setIsOpenEditModal}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading title='Edit key name' subtitle='' />
            <ServerError error={serverError} />
            <TextInput
              type='text'
              name='Key name'
              value={keyName}
              setValue={setKeyName}
              label
            />
            <div className='Header--pulse-grid'>
              <Button
                name='Edit'
                click={editKeyName}
                loading={profileLoading}
                disabled={profileDisabled}
              />
              <Button
                name='Cansel'
                click={(): void => setIsOpenEditModal(false)}
                loading={profileLoading}
                disabled={profileDisabled}
              />
            </div>
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isOpenDeleteModel}
          setIsOpen={setIsOpenDeleteModal}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Delete key'
              subtitle={`Are you sure you want to delete ${selectedKey?.key_name} key?`}
            />
            <ServerError error={serverError} />
            <div className='Header--pulse-grid'>
              <Button
                name='Delete key'
                click={deleteKey}
                loading={profileLoading}
                disabled={profileDisabled}
                color='RED'
              />
              <Button
                name='Cansel'
                click={(): void => {
                  setIsOpenDeleteModal(false);
                }}
                loading={profileLoading}
                disabled={profileDisabled}
              />
            </div>
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Edit key name'
              subtitle='Edit your name and upload an avatar'
            />
            <ServerError error={serverError} />

            <TextInput
              type='text'
              name='Name'
              value={editName}
              setValue={setEditName}
              label
            />
            <AvatarPicker
              onImageUpload={(): void => {}}
              initialImage={image}
              noImage={defaultImage}
            />

            <Button
              name='Save'
              click={saveProfile}
              loading={profileLoading}
              disabled={profileDisabled}
            />
          </div>
        </SideMenu>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={navList} selected='Settings' />
          </div>

          <Card>
            <SettingsItem title='Show File Preview' subtitle=''>
              <Card>
                <SettingsItem
                  inner
                  title='Toggle Previews'
                  subtitle='Show or hide file previews in the file side bar'
                >
                  <Button
                    name={showPreview ? 'On' : 'Off'}
                    margin={0}
                    click={togglePreview}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem>
            {/* <SettingsItem title='Profile' subtitle=''>
              <Card>
                <SettingsItem
                  inner
                  title='Name & Avatar'
                  subtitle='Edit your name and/or avatar'
                >
                  <Button
                    name={'Edit'}
                    margin={0}
                    click={(): void => setIsOpen(true)}
                    loading={toggleLoading}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem> */}
            <SettingsItem title='Organization' subtitle=''>
              <Card>
                <SettingsItem
                  inner
                  title='Create Organization'
                  subtitle='To create an organization, click the “Create" button and complete the form consisting of three steps.'
                >
                  <Button
                    name='Create'
                    margin={0}
                    click={(): void => setIsOpenAddOrgModal(true)}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem>
            {isAuthenticatedViaWebauthn ? (
              <SettingsItem
                title='Encryption'
                subtitle='You can add new key'
                button='Add Key'
                buttonClick={async (): Promise<void> =>
                  authenticateViaWebauthn(true)
                }
              >
                {/* TEST VAR webAuthnPubKeys OR  */}
                {userState.data?.webauthn_pub_keys?.length ? (
                  <Card>
                    <>
                      {userState.data?.webauthn_pub_keys &&
                        userState.data?.webauthn_pub_keys?.map(
                          (key: webAuthnPubKeyShape) => (
                            <div key={key.id} style={{ cursor: 'pointer' }}>
                              <SettingsItem
                                inner
                                title={`${key.key_name || ''}`}
                                subtitle={
                                  <>
                                    <div>{`Created at: ${epochToDate(
                                      new Date(key?.created_at).getTime() || 0,
                                      true
                                    )}`}</div>
                                    <div>{`
                                  Last used at: ${epochToDate(
                                    new Date(key?.last_used_date).getTime() ||
                                      0,
                                    true
                                  )}`}</div>
                                  </>
                                }
                                border
                              >
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto',
                                    gridGap: '6px',
                                  }}
                                >
                                  <Button
                                    name='Edit'
                                    click={(): any => {
                                      setIsOpenEditModal(true);
                                      setSelectedKey(key);
                                    }}
                                  />
                                  <Button
                                    name='Delete'
                                    click={(): any => {
                                      setIsOpenDeleteModal(true);
                                      setSelectedKey(key);
                                    }}
                                    color='RED'
                                  />
                                </div>
                              </SettingsItem>
                            </div>
                          )
                        )}
                    </>
                  </Card>
                ) : (
                  <div className='Users--none'>You don't have any key yet</div>
                )}
              </SettingsItem>
            ) : (
              <SettingsItem title='Encryption' subtitle=''>
                <Card>
                  <SettingsItem
                    inner
                    title='WebAuthn'
                    subtitle='To enable encryption authentication via WebAuthn is required'
                  >
                    <div>
                      {authenticationError && authenticationError.length && (
                        <ServerError error={authenticationError} />
                      )}
                      <Button
                        name={
                          isAuthenticatedViaWebauthn
                            ? 'Encryption Enabled'
                            : 'Enable Encryption'
                        }
                        margin={0}
                        click={async (): Promise<void> =>
                          authenticateViaWebauthn()
                        }
                        disabled={isAuthenticatedViaWebauthn}
                        loading={isAuthenticationLoading}
                      />
                    </div>
                  </SettingsItem>
                </Card>
              </SettingsItem>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Settings;
